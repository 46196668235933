try {
	ymaps.ready(ymap)
} catch (error) { }

function ymap() {
	const MAP = document.querySelector('#Map')

	fetch(MAP.getAttribute('data-url')).then(res => res.json()).then(data => {
		const myMap = new ymaps.Map('Map', {
			center: eval(data.mark),
			zoom: 16,
		})
		const balloon = new ymaps.Placemark(eval(data.mark), {
			balloonContentBody: `
			<div class="ymaps__balloon-content">
				<img class"ymaps__ballon-img" src="${data.logo}">
				<div>${data.title}</div>
				<div>${data.address}</div>
			</div>
			`,
		})
		myMap.geoObjects.add(balloon)
	})
}
