const params = {
    counter: 1,
    step: 10,
    btn: document.querySelector('[show-more]')
}

function showMore() {
    const parent = document.querySelector('.blog__list')
    if (!parent) return

    params.counter += 1

    const items = parent.querySelectorAll('li')
    items.forEach((li, id) => {
        if (id <= params.counter * params.step) {
            li.setAttribute('style', 'max-height: 40000px; min-height: 180px; transition: max-height 10s, opacity 2s; opacity: 1;')
        }
    })

    if (items.length <= params.counter * params.step) params.btn.remove()
}

function hideBlogLi() {
    const parent = document.querySelector('.blog__list')
    if (!parent) return
    const items = parent.querySelectorAll('li')
    items.forEach((li, id) => {
        if (id >= params.step) {
            li.setAttribute('style', 'opacity: 0; transition: all .5s; max-height: 1px; min-height: unset; margin: 0;')
        }
    })

    if (items.length <= params.counter * params.step) params.btn.remove()
}

// 'max-height: 40000px; transition: max-height 10s, opacity 2s; opacity: 1;'