// open modal window
function modal() {
	let pop, form, inputs, mode, closeBtn
	onload = () => {
		if (document.querySelector('.contacts')) {
			let btns = document.querySelectorAll('[data-mw], [data-r]')
			btns.forEach(btn => {
				btn.remove()
			})
			mode = 1
			pop = document.querySelector('.popup-write-us:not(.pgiopup-write-us--resume)')
			form = pop.querySelector('form')
			inputs = pop.querySelectorAll('input:not([type="checkbox"], [type="radio"], [type="file"], [type="hidden"]), textarea')
			open(pop, form, inputs, mode)
		}
	}
	onclick = e => {
		if (e.target.hasAttribute('data-mw') || e.target.closest('[data-mw]')) {
			mode = 1
			pop = document.querySelector('.popup-write-us:not(.popup-write-us--resume)')
			form = pop.querySelector('form')
			inputs = pop.querySelectorAll('input:not([type="checkbox"], [type="radio"], [type="file"], [type="hidden"]), textarea')
			open(pop, form, inputs, mode)
		} else if (e.target.hasAttribute('data-r') || e.target.closest('[data-r]')) {
			mode = 2
			pop = document.querySelector('.popup-write-us--resume')
			form = pop.querySelector('form')
			inputs = pop.querySelectorAll('input:not([type="checkbox"], [type="radio"], [type="file"], [type="hidden"])')
			open(pop, form, inputs, mode)
		}
	}

	function open(pop, form, inputs, mode) {
		pop.classList.remove('popup-write-us--disable')
		pop.classList.add('popup-write-us--enable')
		closeBtn = pop.querySelector('.Button__icon-svg--close')
		if (closeBtn) {
			closeBtn.onclick = () => {
				close(pop)
			}
		}
		openField(inputs)
		validation(form, mode)
	}

	function close(pop) {
		pop.classList.add('popup-write-us--disable')
		pop.classList.remove('popup-write-us--enable')
	}
}

// open text field (animation)
function openField(inputsCollection) {
	inputsCollection.forEach(input => {
		let label = input.closest('label')
		label.onclick = () => {
			input.classList.add('Input__inp--open')
		}
	})
}

// validation form inputs
function validation(form, mode) {
	let inputs
	if (mode === 1) {
		inputs = form.querySelectorAll('input, textarea')
	} else if (mode === 2) {
		inputs = form.querySelectorAll('input')
	}
	inputs.forEach(inp => {
		inp.onchange = inp.oninput = () => {
			if (inp.type === 'file') {
				let icon = inp.closest('.Input').querySelector('.Input__label-file')
				if (inp.value) {
					icon.style = 'opacity: 1;'
				} else {
					icon.style = 'opacity: 0; pointer-events: none;'
				}
			}
			if (inp.type === 'checkbox') {
				if (inp.checked) {
					inp.classList.add('valid')
					inp.classList.remove('invalid')
				} else {
					inp.classList.remove('valid')
					inp.classList.add('invalid')
				}
			} else {
				if (inp.value) {
					inp.classList.add('valid')
					inp.classList.remove('invalid')
					if (inp.type !== 'file' && inp.type !== 'checkbox' && inp.type !== 'radio') {
						inp.classList.add('Input__inp--open')
					}
				} else {
					inp.classList.remove('valid')
					inp.classList.add('invalid')
					if (inp.type !== 'file' && inp.type !== 'checkbox' && inp.type !== 'radio') {
						inp.classList.remove('Input__inp--open')
					}
				}
			}
		}
	})
}

// data collection
function data(data, form) {
	let arr = []
	let formData = new FormData()
	data.forEach(inp => {
		arr.push(inp.getAttribute('name'))
	})
	for (let i = 0; i < arr.length; i++) {
		let key = arr[i]
		if (key === 'file') {
			Array.prototype.forEach.call(form.querySelector(`[name="${key}"]`).files, file => formData.append('file[]', file))
		} else {
			formData.append(key, new FormData(form).get(arr[i]))
		}
	}
	return formData
}

// send api request
function sendRequest(form, url) {
	let inputsVal = form.querySelectorAll('.valid')
	const hiddenInp = form.querySelectorAll('[type="hidden"]')
	let inputs = form.querySelectorAll('input:not([required])')
	let status = document.querySelector('.popup-status')
	if (inputsVal.length >= inputs.length) {
		fetch(`${url}`, {
			method: 'POST',
			body: data([...inputsVal, ...hiddenInp], form),
		}).then(res => {
			if (res.ok) {
				status.classList.remove('popup-status--disable')
				status.classList.add('popup-status--enable')
			} else {
				let title = status.querySelector('[s-title]')
				let text = status.querySelector('[s-text]')
				title.textContent = 'Ошибка!'
				text.innerHTML = 'заявка не отправлена <br> попробуйте ещё раз'
				status.classList.remove('popup-status--disable')
				status.classList.add('popup-status--enable')
			}
		})
	}
}
