function more() {
	let parent = document.querySelector('.vacancy')
	if (!parent) return
	let items = parent.querySelectorAll('.dropdown__item')
	let btn = parent.querySelector('.dropdown__button')
	btn.onclick = e => {
		e.preventDefault()
		items.forEach(item => item.removeAttribute('style'))
		btn.style = 'display: none;'
		return
	}
	Array.prototype.forEach.call(items, (item, id) => {
		if (items.length > 4) {
			if (id > 4) {
				item.style = 'display: none;'
			}
		} else {
			btn.style = 'display: none;'
			return
		}
	})
}
