function swiperMain() {
	if (!document.querySelector('[class^="swiper"]')) return
	if (localStorage.getItem('prog_swiper_first') !== '1') {
		document.body.classList.add('overflow-h')
	} else {
		document.querySelector('.partners__swiper-container').setAttribute('style', 'pointer-events: none;')
		document.querySelector('.hero-slider').setAttribute('style', 'pointer-events: none;')
	}
	let sliderPartners = new Swiper('.partners__swiper-container', {
		direction: 'vertical',
		// loop: true,
		pagination: '.swiper-pagination',
		grabCursor: false,
		speed: 1000,
		paginationClickable: true,
		parallax: true,
		autoplay: false,
		effect: 'slide',
		mousewheel: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		on: {
			progress: function () {
				let swip = document.querySelector('.partners__swiper-container')
				let slides = swip.querySelectorAll('.swiper-slide')
				setTimeout(() => {
					if (slides[slides.length - 1].classList.contains('swiper-slide-active')) {
						localStorage.setItem('prog_swiper_second', '1')
						swip.setAttribute('style', 'pointer-events: none;')
						document.body.classList.remove('overflow-h')
						removeEventListener('scroll', scrollHandler1)
						addEventListener('scroll', function scrollListener() {
							if ($(swip).offset().top + 200 <= this.scrollY || $(swip).offset().top + swip.clientHeight - 200 >= this.scrollY + this.innerHeight) {
								sliderPartners.slideTo(0, 2000)
								this.removeEventListener('scroll', scrollListener)
							}
						})
					}
				}, 500)
				addEventListener('click', e => {
					if (e.target.closest('#home-slider')) {
						swip.removeAttribute('style')
						window.scrollTo({
							top: $('#portfolio').offset().top,
							behavior: 'smooth',
						})
					}
				})
			},
		},
	})
	var sliderSelector = '.section-advantages__swiper-container',
		options = {
			init: false,
			loop: true,
			speed: 800,
			slidesPerView: 2.8, // or 'auto'
			// spaceBetween: 10,
			centeredSlides: true,
			effect: 'coverflow', // 'cube', 'fade', 'coverflow',
			coverflowEffect: {
				rotate: 0, // Slide rotate in degrees
				stretch: 100, // Stretch space between slides (in px)
				depth: 200, // Depth offset in px (slides translate in Z axis)
				modifier: 1,
				slideShadows: true, // Enables slides shadows
			},
			grabCursor: true,
			parallax: true,
			scrollbar: {
				el: '.swiper-scrollbar',
				// hide: true,
			},
			// pagination: {
			// 	el: '.swiper-pagination',
			// 	clickable: true,
			// },
			// navigation: {
			// 	nextEl: '.swiper-button-next',
			// 	prevEl: '.swiper-button-prev',
			// },
			breakpoints: {
				1023: {
					slidesPerView: 2.5,
					spaceBetween: 0,
				},
				733: {
					slidesPerView: 2,
					spaceBetween: 0,
				},
			},
			// Events
			on: {
				imagesReady: function () {
					this.el.classList.remove('loading')
				},
			},
		}
	var mySwiper = new Swiper(sliderSelector, options)

	// Initialize slider
	mySwiper.init()

	onload = scrollHandler()

	function scrollHandler() {
		if (localStorage.getItem('prog_swiper_first') !== '1') addEventListener('scroll', scrollHandler2, { passive: true })
		if (localStorage.getItem('prog_swiper_second') !== '1') addEventListener('scroll', scrollHandler1, { passive: true })
	}

	function scrollHandler1() {
		$('#portfolio').attr('style', 'pointer-events: none;')
		if ($('#portfolio').offset().top - 150 <= scrollY && $('#portfolio').offset().top >= scrollY) {
			document.body.classList.add('overflow-h')
			setTimeout(() => {
				scrollTo({
					top: $('#portfolio').offset().top,
					behavior: 'smooth',
				})
				$('#portfolio').attr('style', '')
			}, 100)
		}
	}
	function scrollHandler2() {
		if ($('#hero').offset().top === scrollY) {
			document.body.classList.add('overflow-h')
			setTimeout(() => {
				scrollTo({
					top: $('#hero').offset().top,
					behavior: 'smooth',
				})
				$('#hero').attr('style', '')
			}, 100)
		}
	}

	// sliderPartners.mousewheel = false
	// HERO SLIDER
	var menu = []
	jQuery('.hero-swiper-slide').each(function (index) {
		menu.push(jQuery(this).find('.slide-inner').attr('data-text'))
	})
	var interleaveOffset = 0.5
	var swiperOptions = {
		speed: 1000,
		parallax: true,
		direction: 'vertical',
		mousewheel: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		// autoplay: {
		// 	delay: 6500,
		// 	disableOnInteraction: false,
		// },
		// watchSlidesProgress: true,
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	clickable: true,
		// },

		// navigation: {
		// 	nextEl: '.swiper-button-next',
		// 	prevEl: '.swiper-button-prev',
		// },

		on: {
			touchStart: function () {
				var swiper = this
				for (var i = 0; i < swiper.slides.length; i++) {
					swiper.slides[i].style.transition = ''
				}
			},

			setTransition: function (speed) {
				var swiper = this
				for (var i = 0; i < swiper.slides.length; i++) {
					swiper.slides[i].style.transition = speed + 'ms'
					swiper.slides[i].querySelector('.slide-inner').style.transition = speed + 'ms'
				}
			},
			progress: function () {
				var swiper = this
				for (var i = 0; i < swiper.slides.length; i++) {
					var slideProgress = swiper.slides[i].progress
					var innerOffset = swiper.width * interleaveOffset
					var innerTranslate = slideProgress * innerOffset
					swiper.slides[i].querySelector('.slide-inner').style.transform = 'translate3d(0, ' + innerTranslate + 'px,  0)'
				}

				let swip = document.querySelector('.hero-slider')
				let slides = swip.querySelectorAll('.swiper-slide')
				setTimeout(() => {
					if (slides[slides.length - 1].classList.contains('swiper-slide-active')) {
						localStorage.setItem('prog_swiper_first', '1')
						swip.setAttribute('style', 'pointer-events: none;')
						document.body.classList.remove('overflow-h')
						removeEventListener('scroll', scrollHandler2)
						addEventListener('scroll', function scrollListener() {
							if ($(swip).offset().top + swip.clientHeight / 2 <= this.scrollY) {
								swiperHero.slideTo(0, 2000)
								this.removeEventListener('scroll', scrollListener)
							}
						})
					}
				}, 100)
				addEventListener('click', e => {
					if (e.target.closest('#hero')) {
						swip.removeAttribute('style')
						window.scrollTo({
							top: $('#hero').offset().top,
							behavior: 'smooth',
						})
					}
				})
			},
		},
	}

	var swiperHero = new Swiper('#hero .swiper-container', swiperOptions)

	// DATA BACKGROUND IMAGE
	var sliderBgSetting = $('.hero-slide-bg')
	sliderBgSetting.each(function (indx) {
		if ($(this).attr('data-background')) {
			$(this).css('background-image', 'url(' + $(this).data('background') + ')')
		}
	})
}
