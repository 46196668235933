function select() {
    const selectSingle = document.querySelector('.__select');
    if (!selectSingle) return
    const selectSingle_title = selectSingle.querySelector('.__select__title');
    const selectSingle_labels = selectSingle.querySelectorAll('.__select__label');

    let parent = document.querySelector('.gallery__wrapper')
    let lists = parent.querySelectorAll('ul')

    localSt(lists)

    if (sessionStorage.getItem('filter')) location.hash = sessionStorage.getItem('filter')

    if (!location.hash && !selectSingle.getAttribute('data-sel')) {
        // location.hash = selectSingle_labels[0].getAttribute('data-sel')
        selectSingle.setAttribute('data-sel', selectSingle_labels[0].getAttribute('data-sel'))
    }

    function hashHandler() {
        let selector = location.hash.slice(1)
        let selected = selectSingle.querySelector(`[data-sel="${selector}"]`)
        let inp = selectSingle.querySelector(`#${selected.getAttribute('for')}`)
        sessionStorage.setItem('filter', location.hash)
        selectSingle_labels.forEach(label => {
            let closestInp = selectSingle.querySelector(`#${label.getAttribute('for')}`)
            closestInp.removeAttribute('checked')
        })

        inp.setAttribute('checked', '')
        selectSingle_title.textContent = selected.textContent
        filter(selector, lists)
    }

    if (location.hash) {
        hashHandler()
    }

    onhashchange = () => {
        hashHandler()
    }

    // Toggle menu
    selectSingle_title.addEventListener('click', () => {
        if ('active' === selectSingle.getAttribute('data-state')) {
            selectSingle.setAttribute('data-state', '');
        } else {
            selectSingle.setAttribute('data-state', 'active');
        }
    });

    // Close when click to option
    for (let i = 0; i < selectSingle_labels.length; i++) {
        selectSingle_labels[i].addEventListener('click', (evt) => {
            selectSingle_title.textContent = evt.target.textContent;
            selectSingle.setAttribute('data-state', '');
        });
    }

    selectSingle_labels.forEach(label => {
        label.onclick = e => {
            selectSingle_title.textContent = e.target.textContent
            selectSingle.setAttribute('data-state', '')
            selectSingle.setAttribute('data-sel', label.getAttribute('data-sel'))
            location.hash = `${selectSingle.getAttribute('data-sel')}`
            sessionStorage.setItem('filter', selectSingle.getAttribute('data-sel'))
            let selector = location.hash.slice(1)
            filter(selector, lists)
        }
    })
}

function preloader(mode) {
    let preloader = document.querySelector('.preloader')
    let body = document.body

    if (mode === 1) {
        preloader.classList.remove('preloader--disabled')
        preloader.classList.add('preloader--enabled')
        body.classList.add('overflow-h')
    } else if (mode === 2) {
        setTimeout(() => {
            preloader.classList.add('preloader--disabled')
            preloader.classList.remove('preloader--enabled')
            body.classList.remove('overflow-h')
        }, 500)
    }
}

function searchHashText(hash) {
    let select = document.querySelector('.__select')
    let result = select.querySelector(`[data-sel="${hash.slice(1)}"]`)
    return result.textContent
}


function filter(filter, lists) {
    preloader(1)
    let parent = document.querySelector('.gallery__wrapper')
    let filteredLi = [], remainingLi = [], allLi, localStorageLists = []
    let newEl

    lists.forEach((list, id) => {
        list.innerHTML = ''
        list = new DOMParser().parseFromString(sessionStorage.getItem(`htmlEl${id}`), "text/html").querySelector('ul')
        allLi = list.querySelectorAll(`li`)
        allLi.forEach(li => {
            if (filter === 'All') {
                filteredLi.push(li)
            } else {
                if (li.hasAttribute(filter)) {
                    filteredLi.push(li)
                } else {
                    remainingLi.push(li)
                }
            }
        })

        newEl = new DOMParser().parseFromString(sessionStorage.getItem(`htmlEl${id}`), "text/html").querySelector('ul')
        localStorageLists.push(newEl)
    })
    parent.innerHTML = ''

    localStorageLists.forEach((list, id) => {
        list.innerHTML = ''
        if (!filteredLi.length) {
            if (id === 0) {
                list.classList.add('gallery__list-area--filter-error')
                list.classList.add('error')
                list.innerHTML = `
                    <div class="error__wrapper">
                        <span class="error__text text-error">
                            Проектов такого типа как 
                            "<strong class='text-error__type'>${searchHashText(location.hash)}</strong>" 
                            пока что нет
                        <span>
                    </div>
                `
                parent.appendChild(list)
            }
        } else {
            if (list.classList.contains('error')) {
                list.classList.remove('gallery__list-area--filter-error')
                list.classList.remove('error')
            }
            filteredLi.forEach((li, index) => {
                if (filteredLi[index + 7 * id]) {
                    list.appendChild(filteredLi[index + 7 * id])
                }
            })
            if (filter === 'All' || filteredLi.length > 7) {
                list.style = 'max-height: 40000px; transition: max-height 10s, opacity 2s; opacity: 1;'
            }
            parent.appendChild(list)
        }
    })
    preloader(2)
}

function localSt(listLs) {
    let result = []
    listLs.forEach((i, id) => {
        let list = i.outerHTML
        sessionStorage.setItem(`htmlEl${id}`, list)
        result.push(sessionStorage.getItem(`htmlEl${id}`))
    })
    return result
}