function Menu() {
	try {
		//! Мобильное меню и гамбургер
		const hamb = document.querySelector('#hamb')
		const Navmain = document.querySelector('.Nav__main')
		const headerMain = document.querySelector('.header')
		const container = document.querySelector('.container')
		const body = document.body

		// Клонируем меню, чтобы задать свои стили для мобильной версии
		const menu = document.querySelector('#menu').cloneNode(1)

		// При клике на иконку hamb вызываем ф-ию hambHandler
		hamb.addEventListener('click', hambHandler)

		// Выполняем действия при клике ..
		function hambHandler(e) {
			e.preventDefault()
			// Переключаем стили элементов при клике
			Navmain.classList.toggle('open')
			headerMain.classList.toggle('open')
			container.classList.toggle('open')
			hamb.classList.toggle('active')
			body.classList.toggle('noscroll')
			renderNav__main()
		}

		// при прокрутке
		window.addEventListener(
			'scroll',
			() => {
				if (scrollY > 50) {
					headerMain.setAttribute('header-dec', '')
				} else {
					headerMain.removeAttribute('header-dec')
				}
			},
			{ passive: true },
		)

		// Здесь мы рендерим элементы в наш попап
		function renderNav__main() {
			Navmain.appendChild(menu)
		}

		// Код для закрытия меню при нажатии на ссылку
		const links = Array.from(menu.children)

		// Для каждого элемента меню при клике вызываем ф-ию
		links.forEach(link => {
			link.addEventListener('click', closeOnClick)
		})

		// Закрытие попапа при клике на меню
		function closeOnClick() {
			Navmain.classList.remove('open')
			hamb.classList.remove('active')
			body.classList.remove('noscroll')
			container.classList.remove('open')
			headerMain.classList.remove('open')
		}

		//! Отступ фиксированного header
		// const heightHeader = document.querySelector('.header').offsetHeight

		// let page = document.querySelector('.ident-menu-js')
		// page.style.paddingTop = heightHeader + 'px'

		//Плавная прокрутка. Якоря
		//вместо a[href^="#"] можно добавлять класс cсылки или индификатор

		$(document).ready(function () {
			$('a[href^="#"]').bind('click', function (e) {
				if (!e.target.closest('a').hasAttribute('data-fancybox-window') && !e.target.closest('a').hasAttribute('data-fancybox')) {
					var anchor = $(this)
					if (window.matchMedia('(max-width: 928px)').matches) {
						$('html, body')
							.stop()
							.animate(
								{
									scrollTop: $(anchor.attr('href')).offset().top,
								},
								800,
							)
					} else {
						$('html, body')
							.stop()
							.animate(
								{
									scrollTop: $(anchor.attr('href')).offset().top - heightHeader,
								},
								800,
							)
					}
					e.preventDefault()

					document.body.classList.remove('body-scroll-hidden')
				}
			})

			return false
		})

		//фиксированная шапка при скроле
		$('header').removeClass('default')
		$(window).scroll(function () {
			if ($(this).scrollTop() > 40) {
				$('header').addClass('default').fadeIn('fast')
			} else {
				$('header').removeClass('default').fadeIn('fast')
			}
		})
	} catch (e) {
		console.log('Ошибка heightHeadermain')
	}
}
