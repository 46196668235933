document.addEventListener('DOMContentLoaded', function () {
	swiperMain()
	Menu()
	AnimationCounter()
	modal()
	inputMaskCustom()
	OpenCloseCard()
	getProjects()
	anchor()
	more()
	hideBlogLi()
})
